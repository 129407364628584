/* CSS code with corrections */

.feature-section {
  text-align: center;
  height: 75vh;
  background-color: magenta;
}

.section-title {
  font-size: 40px;
  color: blue;
  margin-bottom: 20px;
  padding-top: 20px;
  font-weight: 700;
}

.image-container {
  max-width: 50%;
  margin: 0 auto;
  height: 400px;
  position: relative;
  display: flex; /* Add flex display */
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  width: 50%;
  text-align: center;
  border-radius: 20px;
  left: 650px;
}

.image.active {
  opacity: 1;
}

.description {
  color: white;
  font-weight: 600;
  padding: 10px;
  position: absolute;
  bottom: 100px; /* Corrected unit */
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  top: 400px;
}

.centered-button {
  position: relative;
  top: 150px;
  margin-bottom: 50px;
  left: 900px;
  transform: translateX(-50%);
  background-color: yellow;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: larger;
}

.centered-button:hover {
  background-color: lightyellow;
}

/* Media queries for responsiveness */



@media screen and (min-width: 768px) and (max-width: 1400px) {
  .feature-section {
    height: 85vh;
  }
  
  .section-title {
    font-size: 30px;
  }
  
  .image-container {
    max-width: 50%;
    height: 43vh;
    margin-top: 50px;
  }
  .image-container p{
    top: 500px;
    font-size: 24px;
    font-weight: 600;
  }

  .centered-button {
    top: 170px;
    padding-bottom: 3px;
    padding-top: 3px;
    left: 700px;
  }

  .image {
    width: 90%;
    height: 50vh;
    margin-bottom: 50px;
    left: 350px;
    margin-top: 40px;
  }
  .description {
      
      color: white;
      font-weight: 400;
      padding: 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 15px;
    
  }
}

@media screen and (min-width:300px) and (max-width: 768px) {
  .section-title {
    font-size: 30px;
  }

  .feature-section {
    height: 75vh;
  }

  .description {
    margin-top: 200px;
    font-size: 30px;
  }

  .image {
    height: 40vh;
    margin-bottom: 50px;
    margin-right: 10px;
    margin-top: 90px;
    width: 90%;
    left: 20px;
  }

  .image-container {
    max-width: 100%;
    height: 43vh;
  }
  .image-container p{
    font-size: 20px;
    top: 300px;
  }
  .centered-button{
    top: 100px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

@media screen and (min-width: 2420px) and (max-width: 2500px) and (min-height: 700px) and (max-height: 800px){

  .feature-section {
    height: 90vh;
  }

  .image {
    height: 60vh;
    margin-bottom: 50px;
    margin-right: 10px;
    margin-top: 130px;
    width: 40%;
    left: 800px;
  }
  .image-container {
    max-width: 50%;
    height: 43vh;
    left: 100px;
  }
  .centered-button{
    padding-top: 5px;
    padding-bottom: 5px;
    left: 1150px;
    justify-content: center;
    
  }

}
